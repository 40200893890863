import { SideBar } from "./SideBar";
import "./contentLayout.css";

export const ContentLayout = ({
  sidebarHeaderText,
  routeItemList,
  contentHeading,
  images,
  mainContent,
}) => {
  return (
    <div className="content-layout-container">
      <SideBar headerText={sidebarHeaderText} routeItemList={routeItemList} />
      <div className="content">
        {contentHeading && <h2>{contentHeading}</h2>}
        {/* {images && images?.length > 0 && (
          <div className="image-grid">
            {images?.map((image, index) => (
              <img src="image1.jpg" alt={`{index + 1}`} />
            ))}
          </div>
        )} */}
        <p>{mainContent}</p>
      </div>
    </div>
  );
};
