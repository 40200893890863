import { ContentLayout } from "../../ContentLayout";
import { routes } from "../../../constants";
import CampusPhoto from "../../../Assests/photo-university-front.jpeg"

export const LocationCampus = () => {
  const theUniversityRoutes = routes.filter(
    (route) => route.name === "The University"
  )[0];

  const mainContent = () => {
    return (
      <div className="introduction">
        <img className="campus-photo" src={CampusPhoto} alt="campus"/>
        <p>
          S.S Mahavidyalaya is located in Tamkuhi Raj, Kushinagar, and features
          a lush green campus that spans a considerable area. As you enter its
          gate, the first sight that greets you is the abundance of greenery.
          The wooded acreages of S.S Mahavidyalaya, situated just about 2
          kilometers south of the Tamkuhi Raj Bus Station, provide an ideal
          environment for academic pursuits. The serene and verdant surroundings
          of the campus contribute to an atmosphere conducive to learning and
          personal development.
        </p>
      </div>
    );
  };
  return (
    <ContentLayout
      routeItemList={theUniversityRoutes}
      contentHeading="Location & Campus"
      sidebarHeaderText="The University"
      mainContent={mainContent()}
      images={[]}
    />
  );
};
