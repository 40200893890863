import {
  FaEnvelope,
  FaFacebook,
  FaLinkedinIn,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { TM } from "./TM";
export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-section contact">
        <h3>CONTACT US</h3>
        <div className="text-align-start p-margin">
          <address>
            S. S. MAHAVIDYALAYA (Arts & Sciences), Tamkuhi Raj, Kushinagar
          </address>
          <strong>Managing Director:</strong> Dr. Udai Narayan Gupta
          <p>
            Phone:{" "}
            <a href="tel:+91-9918854303" className="white">
              +91-9918854303
            </a>{" "}
            (during office hours)
          </p>
          <p>
            Email:{" "}
            <a href="mailto:ssmahavidyalaya.ssmu@gmail.com" className="white">
              ssmahavidyalaya.ssmu@gmail.com
            </a>
          </p>
          <strong>Admission Enquiry: </strong>
          <a href="tel:+91-9918854303" className="white">
            +91-9918854303
          </a>{" "}
          <a href="tel:+91-8858819583" className="white">
            +91-7355490176
          </a>
          <br />
          (On all working days between 9:30 am to 5:00 pm)
        </div>
        <div className="social-media">
          <span className="get-in-touch">Get In Touch:</span>
          <a href="x.com" className="social-icon twitter">
            <FaFacebook className="footer-icon margin-top-10" />
          </a>
          <a href="linkedin.in" className="social-icon linkedin">
            <FaLinkedinIn className="footer-icon margin-top-10" />
          </a>
          <a href="youtube.com" className="social-icon youtube">
            <FaYoutube className="footer-icon margin-top-10" />
          </a>
          <a
            href="mailto:ssmahavidyalaya@ssmu.ac.in"
            className="social-icon Email"
          >
            <FaEnvelope className="footer-icon margin-top-10" />
          </a>
          <a href="tel:+91-9918854303" className="social-icon Call">
            <FaPhoneAlt className="footer-icon margin-top-10" />
          </a>
        </div>
      </div>

      <div className="footer-section media">
        <h3>Media</h3>
        <ul>
          <li>Photo Gallery</li>
          <li>Video Gallery</li>
          <li>Media Coverage</li>
        </ul>
      </div>

      <div className="footer-section important-links">
        <h3>Important Links</h3>
        <ul>
          <li>Tender</li>
          <li>NIRF</li>
          <li>Useful Links</li>
          <li>Right to Information</li>
          <li>Raj Bhawan, U.P.</li>
        </ul>
      </div>

      <div className="footer-section quick-links">
        <h3>Quick Links</h3>
        <ul>
          <li>Important Cells</li>
          <li>Webmail</li>
          <li>Invitation for Donations</li>
          <li>NPTEL Video Courses</li>
        </ul>
      </div>
      <div className="footer-bottom">
        <p>
          This is the official Website of S. S. MAHAVIDYALAYA (College of Arts &
          Sciences).
        </p>
        <p>
          <a href="google.com">Copyright Statement</a> |
          <a href="google.com"> Hyperlinking Policy</a> |
          <a href="google.com"> Privacy Policy</a> |
          <a href="google.com"> Disclaimer</a> | Created By
          <a href="google.com">
            Shivani
            <TM />
          </a>
        </p>
      </div>
    </footer>
  );
};
