import { Link } from "react-router-dom";

export const SideBar = ({ headerText, routeItemList }) => {
  return (
    <div className="sidebar">
      <h2>{headerText}</h2>
      <ul>
        {routeItemList?.children?.map((routeItem) => {
          return (
            <li key={routeItem?.name} className="sidebar-item">
              <Link to={routeItem?.path}>{routeItem?.name}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
