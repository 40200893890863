import React, { useState } from "react";
import "./dropdown.css";
import { Link } from "react-router-dom";

const Dropdown = ({ routeObj }) => {
  const [aboutUsOpen, setAboutUsOpen] = useState(false);

  return (
    <div
      className="menu-item"
      onMouseEnter={() => setAboutUsOpen(true)}
      onMouseLeave={() => setAboutUsOpen(false)}
    >
      <Link to={routeObj?.path}>{routeObj?.name}</Link>
      {aboutUsOpen && (
        <div className="dropdown">
          {routeObj?.children?.map((item) => {
            return <DropdownItem key={item.name} item={item} />;
          })}
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ item }) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <div
      className="dropdown-item"
      onMouseEnter={() => setSubMenuOpen(true)}
      onMouseLeave={() => setSubMenuOpen(false)}
    >
      {item?.children ? (
        <>
          <Link
            className="black"
            to={item?.path}
            onClick={() => {
              console.log("item?.path clicked", item?.path);
            }}
          >
            {item?.name}
          </Link>
          {subMenuOpen && (
            <div className="sub-dropdown">
              {item?.children?.map((subItem) => (
                <div className="sub-dropdown-item" key={subItem.name}>
                  {subItem.name}
                </div>
              ))}
            </div>
          )}
        </>
      ) : (
        <Link className="black" to={item?.path}>
          {item?.name}
        </Link>
      )}
    </div>
  );
};
export default Dropdown;
