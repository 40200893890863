import ImageSlider from "./ImageSlider";
import image1 from "../Assests/images/image1.jpg";
import image2 from "../Assests/images/image2.jpg";
import image3 from "../Assests/images/image3.jpg";
import image4 from "../Assests/images/image4.jpg";

export const Homepage = () => {
  const images = [
    { id: 1, alt: "Main entrace to the campus", src: image1 },
    { id: 2, alt: "First building on the campus", src: image2 },
    { id: 3, alt: "Grounds on campus", src: image3 },
    { id: 4, alt: "First building entrance", src: image4 },
  ];

  return (
    <div>
      <ImageSlider imageProps={images} />
    </div>
  );
};
