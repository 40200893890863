import React, { useState } from "react";
import "./navbar.css";
import { routes } from "../../constants";
import Dropdown from "../Dropdown/Dropdown";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <nav className="navbar">
      <div className="hamburger-menu" onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      {routes.map((route) => {
        const dropDown = route?.children && <Dropdown routeObj={route} />;

        if (route.icon) {
          return (
            <Link
              className={`icon-rounded-info navbar-links ${
                menuVisible ? "active" : ""
              }`}
              to={route?.path}
            >
              <img
                src={route.icon}
                alt={route.name}
                className="padding-left-3"
              />
            </Link>
          );
        } else {
          return (
            <Link
              to={route?.path}
              className={`navbar-links ${menuVisible ? "active" : ""}`}
            >
              {route?.children ? dropDown : route?.name}
            </Link>
          );
        }
      })}
      {/* </span> */}
    </nav>
  );
};

export default Navbar;
