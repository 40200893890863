import React from "react";
import "./App.css";
import Header from "./Components/Header/Header";
import Navbar from "./Components/Navbar/Navbar";

import { Footer } from "./Components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./constants";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="container">
          <Header />
          <Navbar />
          <div className="main-content">
            <Routes>
              {routes.map((route) => {
                return (
                  <>
                    <Route path={route?.path} element={route?.element}>
                      {route.children?.map((childRoute) => (
                        <Route
                          key={childRoute.path}
                          path={childRoute.path}
                          element={childRoute.element}
                        />
                      ))}
                    </Route>
                  </>
                );
              })}
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
