import { ContentLayout } from "../../ContentLayout";
import { routes } from "../../../constants";

export const VisionMission = () => {
  const theUniversityRoutes = routes.filter(
    (route) => route.name === "The University"
  )[0];

  const mainContent = () => {
    return (
      <div className="introduction">
        <h2>Vision / दृष्टि</h2>
        <p>
          Our vision is to cultivate an environment where academic excellence,
          personal growth, and social responsibility converge, creating
          empowered individuals who can contribute meaningfully to society and
          drive positive change. We aim to be an institution of academic
          excellence, generating knowledge through research by integrating local
          wisdom with global expertise. We nurture talent and promote
          intellectual growth, and we are committed to serving humanity and
          contributing to the social, economic, cultural, and spiritual
          development of society and the nation. Guided by our motto,
          "Enlightening Minds, Empowering Futures," we strive to illuminate
          pathways for success and progress.
        </p>
        <p>
          हमारा दृष्टिकोण एक ऐसे वातावरण को विकसित करना है जहाँ शैक्षिक
          उत्कृष्टता, व्यक्तिगत विकास और सामाजिक जिम्मेदारी मिलकर सशक्त
          व्यक्तियों का निर्माण करें, जो समाज में सार्थक योगदान दे सकें और
          सकारात्मक परिवर्तन ला सकें। हम अकादमिक रूप से एक उत्कृष्ट संस्था बनने
          का लक्ष्य रखते हैं, जो स्थानीय प्रज्ञा एवं वैश्विक दक्षता को एकीकृत कर
          ज्ञानोपार्जन करती है। हम प्रतिभा का संपोषण एवं बौद्धिक उन्नयन को
          प्रोत्साहित करते हैं। हम मानवता की सेवा करने और समाज व राष्ट्र के
          सामाजिक, आर्थिक, सांस्कृतिक एवं आध्यात्मिक विकास में योगदान देने के
          लिए कटिबद्ध हैं। हमारे सूत्र-वाक्य "बुद्धि को प्रबुद्ध करना, भविष्य को
          सशक्त बनाना" से प्रेरित होकर, हम सफलता और प्रगति के मार्ग को प्रज्वलित
          करने का प्रयास करते हैं।
        </p>
        <h2>Mission / लक्ष्य</h2>
        <p>
          <ul>
            <li>
              To provide students with an academically congenial and safe
              environment conducive to their holistic development.
            </li>
            
              {" "}
              विद्यार्थियों को उनके सर्वांगीण विकास में सहायक अकादमिक दृष्टि से
              अनुकूल एवं सुरक्षित वातावरण उपलब्ध कराना।
        
            <li>
              To achieve and ensure intellectual excellence through effective
              teaching, the latest research, and relevant co-curricular
              activities.
            </li>
            
              {" "}
              प्रभावी अध्यापन, अद्यतन शोध एवं उपयुक्त पाठ्यानुवर्ती गतिविधियों
              के माध्यम से बौद्धिक उत्कृष्टता को सुनिश्चित और अर्जित करना।
            
            <li>
              To equip students with academic and professional skills, ethical
              values, and leadership qualities to enable them to contribute to
              social, regional, and national development.
            </li>
            
              विद्यार्थियों को सामाजिक, क्षेत्रीय एवं राष्ट्रीय विकास में योगदान
              देने में समर्थ बनाने के लिए उन्हें अकादमिक एवं व्यावसायिक कौशल,
              नैतिक मूल्य एवं नेतृत्व क्षमता से सम्पन्न बनाना।
            
            <li>
              To sensitize the University community to the needs of the region
              through knowledge-transfer and outreach programs.
            </li>
            
              विश्वविद्यालय समुदाय को ज्ञानांतरण एवं परिसरेतर कार्यक्रमों के
              माध्यम से क्षेत्र की आवश्यकताओं के प्रति संवेदनशील बनाना।
            
            <li>
              To strive for the optimum use of available resources in making the
              campus sustainable, clean, green, and eco-friendly.
            </li>
            
              परिसर को संवहनीय, स्वच्छ, हरा-भरा तथा पर्यावरण-अनुकूल बनाने के लिए
              उपलब्ध संसाधनों के न्यायोचित प्रयोग के लिए प्रयत्नशील रहना।
            
            <li>
              To promote the principles of social, cultural, and religious
              co-existence among students, faculty members, administrative
              officers, and staff of the University.
            </li>
            
              विश्वविद्यालय के विद्यार्थियों, शिक्षकों, प्रशासनिक अधिकारियों और
              कर्मचारियों के बीच सामाजिक, सांस्कृतिक एवं धार्मिक सह-अस्तित्व के
              सिद्धांतों को बढ़ावा देना।
            
          </ul>
        </p>
      </div>
    );
  };
  return (
    <ContentLayout
      routeItemList={theUniversityRoutes}
      contentHeading={null}
      sidebarHeaderText="The University"
      mainContent={mainContent()}
      images={[]}
    />
  );
};
