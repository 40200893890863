import { ContentLayout } from "../../ContentLayout";
import { routes } from "../../../constants";
import { FaBuildingColumns } from "react-icons/fa6";
import { PiMapPinAreaFill } from "react-icons/pi";
import { FaCalendarAlt } from "react-icons/fa";
import { FaServer } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import "./UniversityInFigures.css";

export const UniversityFigures = () => {
  const theUniversityRoutes = routes.filter(
    (route) => route.name === "The University"
  )[0];

  const mainContent = () => {
    // return <p>Main content</p>;

    return (
      <div className="university-in-figures">
        <div className="figures-container">
          <div className="figure-item">
            <FaCalendarAlt className="university-figures-icon" />
            <div className="figure-text">
              <p>Year of Establishment</p>
              <p>2024</p>
            </div>
          </div>
          <div className="figure-item">
            <PiMapPinAreaFill className="university-figures-icon" />
            <div className="figure-text">
              <p>Area of Campuses</p>
              <p>90000 square feet</p>
            </div>
          </div>
          <div className="figure-item">
            <FaBuildingColumns className="university-figures-icon" />
            <div className="figure-text">
              <p>Number of Campus</p>
              <p>1</p>
            </div>
          </div>
        </div>
        <div className="figures-container">
          <div className="figure-item">
            <FaServer className="university-figures-icon" />
            <div className="figure-text">
              <p>Faculties</p>
              <p>10</p>
            </div>
          </div>
          <div className="figure-item">
            <FaBook className="university-figures-icon" />
            <div className="figure-text">
              <p>Departments</p>
              <ul>
                <li>
                  Arts
                </li>
                <li>
                  Commerce
                </li>
                <li>
                  Science
                </li>
              </ul>
            </div>
          </div>
          <div className="figure-item">
            <FaBuilding className="university-figures-icon" />
            <div className="figure-text">
              <p>Programs Offered</p>
              <p>5</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ContentLayout
      routeItemList={theUniversityRoutes}
      contentHeading="University In Figures"
      sidebarHeaderText="The University"
      mainContent={mainContent()}
      images={[]}
    />
  );
};
