import { ContentLayout } from "../../ContentLayout";
import { routes } from "../../../constants";
import Logo from "../../../Assests/ss-university-logo.jpeg";

export const Media = () => {
  const theUniversityRoutes = routes.filter(
    (route) => route.name === "The University"
  )[0];

  const mainContent = () => {
    return (
      <div className="introduction">
        <h2>College Logo</h2>
        <img className="logo-download" src={Logo} alt="university-logo" />
      </div>
    );
  };
  return (
    <ContentLayout
      routeItemList={theUniversityRoutes}
      contentHeading=""
      sidebarHeaderText="The University"
      mainContent={mainContent()}
      images={[]}
    />
  );
};
