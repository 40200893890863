import { ContentLayout } from "../../ContentLayout";
import { routes } from "../../../constants";
import "./theUniversity.css";

export const Introduction = () => {
  const theUniversityRoutes = routes.filter(
    (route) => route.name === "The University"
  )[0];

  const mainContent = () => {
    return (
      <div className="introduction">
        <p>
          S.S. Mahavidyalaya, established in 2024, is a newly-founded
          institution of higher learning affiliated with Deen Dayal Upadhyaya
          Gorakhpur University. Embracing the university's esteemed legacy and
          commitment to excellence, S.S. Mahavidyalaya aims to be a beacon of
          knowledge and holistic education in the region.
        </p>
        <p>
          Situated in the historically and culturally rich town of Tamkuhi Raj,
          the college draws inspiration from the region's spiritual,
          philosophical, and philanthropic heritage, influenced by figures such
          as Maharaja Tamkuhi, Maharaja Digvijay Singh, and others. The
          institution is named after Shri Shivdhari Shah, reflecting a
          dedication to his vision and values.
        </p>
        <p>
          Spread across a thoughtfully designed campus, S.S. Mahavidyalaya
          offers state-of-the-art facilities to foster a conducive learning
          environment. These include a library, modern classrooms &
          laboratories, a health center. The campus also boasts sports and
          recreational facilities, ensuring a balanced development for students.
        </p>
        <p>
          एस.एस. महाविद्यालय, 2024 में स्थापित, उच्च शिक्षा का एक नवस्थापित
          संस्थान है जो दीन दयाल उपाध्याय गोरखपुर विश्वविद्यालय से संबद्ध है।
          विश्वविद्यालय की प्रतिष्ठित धरोहर और उत्कृष्टता की प्रतिबद्धता को
          अपनाते हुए, एस.एस. महाविद्यालय इस क्षेत्र में ज्ञान और समग्र शिक्षा का
          एक प्रकाशस्तंभ बनने का लक्ष्य रखता है।
        </p>

        <p>
          ऐतिहासिक और सांस्कृतिक रूप से समृद्ध टमकुही राज नगर में स्थित, यह
          कॉलेज क्षेत्र की आध्यात्मिक, दार्शनिक और परोपकारी धरोहर से प्रेरणा
          लेता है, जिसे महाराजा दिग्विजय सिंह और अन्य महान व्यक्तियों के योगदान
          ने प्रभावित किया है।{" "}
        </p>
        <p>
          इस संस्थान का नाम श्री शिवधारी शाह के नाम पर रखा गया है, जो उनके
          दृष्टिकोण और मूल्यों के प्रति समर्पण को दर्शाता है। सोच-समझकर डिजाइन
          किए गए परिसर में फैला एस.एस. महाविद्यालय एक अनुकूल शिक्षण वातावरण को
          बढ़ावा देने के लिए अत्याधुनिक सुविधाएं प्रदान करता है। इनमें एक
          पुस्तकालय, आधुनिक कक्षाएं और प्रयोगशालाएं, एक स्वास्थ्य केंद्र शामिल
          हैं। परिसर में खेल और मनोरंजन सुविधाएं भी हैं, जो छात्रों के संतुलित
          विकास को सुनिश्चित करती हैं।
        </p>
      </div>
    );
  };
  return (
    <ContentLayout
      routeItemList={theUniversityRoutes}
      contentHeading="Introduction"
      sidebarHeaderText="The University"
      mainContent={mainContent()}
      images={[]}
    />
  );
};
