import React from "react";
import "./header.css";
import logo from "../../Assests/ss-university-logo.jpeg";

const Header = () => {
  return (
    <header className="header">
      <div className="header-left">
        <img src={logo} alt="MMM Logo" className="logo" />
      </div>
      <div className="university-details">
        <h1>S. S. MAHAVIDYALAYA (College of Arts & Sciences)</h1>
        <p> प्रौद्योगिकी महाविद्यालय, तमकुही राज, कुशीनगर, गोरखपुर (उ.प्र.) भारत</p>
        <p>Approved by AICTE & AFFILIATED TO Deen Dayal Upadhyaya Gorakhpur University</p>
        
      </div>
      <div className="header-right"></div>
    </header>
  );
};

export default Header;
