import { Introduction } from "./Components/UniversityInfo/TheUniversity/Introduction";
import { VisionMission } from "./Components/UniversityInfo/TheUniversity/VisionMission";
import { CoreValues } from "./Components/UniversityInfo/TheUniversity/CoreValues";
import { LocationCampus } from "./Components/UniversityInfo/TheUniversity/LocationCampus";
import { UniversityFigures } from "./Components/UniversityInfo/TheUniversity/UniversityFigures";
import { Media } from "./Components/UniversityInfo/TheUniversity/Media";
import { Careers } from "./Components/UniversityInfo/Careers";
// import { Academics } from "./Components/UniversityInfo/Academics";
// import { CampusFacilities } from "./Components/UniversityInfo/CampusFacilities";
import { ExaminationResults } from "./Components/UniversityInfo/ExaminationResults";
import Phone from "./Assests/Phoneicon.svg";
import Home from "./Assests/Homeicon.svg";
import Email from "./Assests/Emailicon.svg";
import { Homepage } from "./Components/Home";
import { Outlet } from "react-router-dom";

export const routes = [
  {
    name: "Home",
    path: "/",
    icon: Home,
    element: <Homepage />,
  },
  {
    name: "The University",
    // path: "/theUniversity",
    element: <Outlet />, // Use Outlet for nested routes
    children: [
      {
        name: "Introduction",
        path: "/theUniversity/introduction",
        element: <Introduction />,
      },
      {
        name: "Vision & Mission",
        path: "/theUniversity/visionMission",
        element: <VisionMission />,
      },
      {
        name: "Core Values",
        path: "/theUniversity/coreValues",
        element: <CoreValues />,
      },
      {
        name: "Location & Campus",
        path: "/theUniversity/locationCampus",
        element: <LocationCampus />,
      },
      {
        name: "Media",
        path: "/theUniversity/media",
        element: <Media />,
      },
      {
        name: "University in Figures",
        path: "/theUniversity/universityFigures",
        element: <UniversityFigures />,
      },
    ],
  },
  {
    name: "Academics",
    // path: "/academics",
    element: <Outlet />, // Use Outlet for nested routes
    children: [
      {
        name: "Faculties, Institutes & Departments",
        path: "faculties-institutes-departments",
        element: <div>Faculties, Institutes & Departments Component</div>,
        children: [
          {
            name: "History",
            path: "history",
            element: <div>History Component</div>,
          },
          {
            name: "Founders",
            path: "founders",
            element: <div>Founders Component</div>,
          },
        ],
      },
      {
        name: "Academic Calendar",
        path: "academic-calendar",
        element: <div>Academic Calendar Component</div>,
      },
      {
        name: "Programmes Offered",
        path: "programmes-offered",
        element: <div>Programmes Offered Component</div>,
      },
      {
        name: "List of Faculty Members",
        path: "list-of-faculty-members",
        element: <div>List of Faculty Members Component</div>,
      },
      {
        name: "NPTEL Online Courses",
        path: "nptel-online-courses",
        element: <div>NPTEL Online Courses Component</div>,
      },
    ],
  },
  {
    name: "Campus Facilities",
    path: "/campusFacilities",
    element: <Outlet />, // Use Outlet for nested routes
    children: [
      {
        name: "Health Center",
        path: "health-center",
        element: <div>Health Center Component</div>,
      },
      {
        name: "Library",
        path: "library",
        element: <div>Library Component</div>,
      },
      {
        name: "Sports Complex",
        path: "sports-complex",
        element: <div>Sports Complex Component</div>,
      },
    ],
  },
  {
    name: "Examination & Results",
    path: "/examinationresults",
    element: <ExaminationResults />, // Use Outlet for nested routes
    children: [
      {
        name: "Admit Card",
        path: "admit-card",
        element: <div>Admit Card Component</div>,
      },
      {
        name: "Examination Schedule/ Notices",
        path: "examination-schedule-notices",
        element: <div>Examination Schedule/ Notices Component</div>,
      },
      {
        name: "Results",
        path: "results",
        element: <div>Results Component</div>,
      },
    ],
  },
  {
    name: "Careers",
    path: "/careers",
    element: <Careers />, // Use Outlet for nested routes
    children: [
      {
        name: "Vacancies",
        path: "vacancies",
        element: <div>Vacancies Component</div>,
      },
      {
        name: "Recruitment Portal",
        path: "recruitment-portal",
        element: <div>Recruitment Portal Component</div>,
      },
      {
        name: "Rules & Regulations",
        path: "rules-regulations",
        element: <div>Rules & Regulations Component</div>,
      },
    ],
  },
  {
    name: "Phone",
    path: "tel:+919918854303",
    icon: Phone,
  },
  {
    name: "Email",
    path: "mailto:ssmahavidyalaya.ssmu@gmail.com",
    icon: Email,
  },
];
