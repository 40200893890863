import { ContentLayout } from "../../ContentLayout";
import { routes } from "../../../constants";

export const CoreValues = () => {
  const theUniversityRoutes = routes.filter(
    (route) => route.name === "The University"
  )[0];

  const mainContent = () => {
    return (
      <div className="introduction">
        <h2>Core Values / मूल सिद्धान्त</h2>
        <p>
          <ul>
            <li>
              Academic Integrity: Upholding the highest standards of honesty and
              ethics in all academic endeavors.
            </li>{" "}
            शैक्षणिक ईमानदारी: सभी शैक्षणिक प्रयासों में उच्चतम स्तर की ईमानदारी
            और नैतिकता को बनाए रखना।
            <li>
              Accountability and Transparency: Ensuring responsibility with
              transparency, respect, and tolerance for the views of all
              stakeholders.
            </li>{" "}
            जवाबदेही और पारदर्शिता: सभी हितधारकों के विचारों का सम्मान और
            सहनशीलता के साथ जिम्मेदारी और पारदर्शिता सुनिश्चित करना।
            <li>
              Social Responsibility: Demonstrating a commitment to social
              responsibilities and addressing national and international issues
              requiring technological intervention.
            </li>
            सामाजिक जिम्मेदारी: सामाजिक जिम्मेदारियों के प्रति प्रतिबद्धता
            दिखाना और तकनीकी हस्तक्षेप की आवश्यकता वाले राष्ट्रीय और
            अंतरराष्ट्रीय मुद्दों का समाधान करना।
            <li>
              Creativity and Intellectual Excellence: Recognizing and fostering
              creativity and intellectual excellence.
            </li>
            रचनात्मकता और बौद्धिक उत्कृष्टता: रचनात्मकता और बौद्धिक उत्कृष्टता
            को पहचानना और प्रोत्साहित करना।
            <li>
              Spirit of Innovation: Encouraging a spirit of learning,
              exploration, innovation, and enterprise.
            </li>
            नवाचार की भावना: शिक्षा, अन्वेषण, नवाचार और उद्यमिता की भावना को
            प्रोत्साहित करना।
            <li>
              Comprehensive Knowledge: Promoting a holistic understanding and
              knowledge of the human sciences.
            </li>
            समग्र ज्ञान: मानव विज्ञान का समग्र समझ और ज्ञान को बढ़ावा देना।
          </ul>
        </p>
      </div>
    );
  };
  return (
    <ContentLayout
      routeItemList={theUniversityRoutes}
      contentHeading=""
      sidebarHeaderText="The University"
      mainContent={mainContent()}
      images={[]}
    />
  );
};
